import React, {useEffect, useRef} from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import { Image } from "../../components/image"
import { setupScroll } from "../../utils/scroll"
import { buildSeo, SITE_URL } from "../../utils/seo"
import {MDXComponents} from "../../components/components";
import copy from "copy-to-clipboard";
import Button from "../../components/button";
import {LeaveFragment} from "../../components/fragments/leave-fragment";
import {Video} from "../../components/cases/video";
import Sticker from "../../components/sticker";

const MDXProvidersComponents = {...MDXComponents}

const ArticlePage = (data)  => {
  //const { slug, articleNode, previous, next } = pageContext
  const { articleNode } = data.pageContext;
  const scrollTo = useRef();

  const { title, description, thumbnail, project_date, project_type, featured_medias, metaDescription, metaTitle, tasks, technos, thumbnail_bg_1, thumbnail_bg_2 } = articleNode.frontmatter;



  useEffect(( ) => {
    setupScroll();
  }, []);

  return (
    <div  className="project-details">
      {buildSeo({
        title: metaTitle,
        description: metaDescription,
        image: `${SITE_URL}/images/${thumbnail}`
      })}
      <section className="hero-project">
          <div className={"left-item"}>
              <div className={""}>
                  <h1 className={"h1"}>{title}</h1>
                  <div className={"data-table"}>
                      <div className={"data-row"}>
                          <div className={"data-row-name"}>date.</div>
                          <div className={"data-row-value"}>{project_date}</div>
                      </div>
                      {
                          technos && (
                              <div className={"data-row"}>
                                  <div className={"data-row-name"}>technos.</div>
                                  <div className={"data-row-value"}>{technos.map(v => (<div>{v}</div>))}</div>
                              </div>
                          )
                      }
                      <div className={"data-row"}>
                          <div className={"data-row-name"}>missions.</div>
                          <div className={"data-row-value"}>{tasks.map(v => (<div>{v}</div>))}</div>
                      </div>
                  </div>
                  {description.map((v,i) => {
                      return (<p key={i}>{v}</p>);
                  })}
                  <Button callback={() => {
                      scrollTo.current.scrollIntoView({behavior: "smooth"});
                  }} name={"Découvrir"}/>
              </div>
          </div>
          <div className={"thumb-container"}>
              {featured_medias && (
                  featured_medias.map(
                      (v, i) => (
                          <div className={v.type} key={i}>
                              {v.type == "image" && (<Image className="thumb" src={v.media} alt={v.title}/>)}
                              {v.type == "small_image" && (<Image className="thumb" src={v.media} alt={v.title}/>)}
                              {v.type == "video" && (<Video url={v.media}/>)}
                          </div>
                      )
                  )
              )}
              {
                  !featured_medias && (
                      <Image className="thumb" src={thumbnail} alt={title}/>
                  )
              }
          </div>
      </section>
        <div ref={scrollTo}>
            <MDXProvider components={MDXProvidersComponents}>
                <MDXRenderer>{articleNode.body}</MDXRenderer>
            </MDXProvider>

            <section className={"container py-16 to-logo__blue"}>
                <div className={"big-cta"}>
                    <div className="light light-yellow"></div>
                    <div className={"background-title-container"}>
                        <span className={"background-title pb-8 mobile-hide"}>On discute ?</span>
                        <h2>Vous voulez un site<br/>
                            qui émerveille vos clients ?</h2>
                        <p>Si ça vous dit, on peut discuter de votre projet en détails, et je vous ferais un devis sur mesure.</p>
                        <Button link={'/contact/'} name={'Dites m\'en plus ici 👋'}/>
                    </div>
                </div>
            </section>
            <LeaveFragment/>
        </div>
        <button onClick={() => {
            const data = {
                title: title,
                text: description[0],
                url: window.location.href,
            };

            if(navigator && navigator.share) {
                navigator.share(data)
            }
            else {
                if(window.location)
                    copy(window.location.href);
            }
        }} className={'btn-circle btn-secondary btn-icon btn-share'} aria-label={'Share project'} />
    </div>
  )
}
export default ArticlePage
